<template>
  <v-card-text>

    <v-list dense>
      <v-list-item class="mb-4">
        <v-list-item-content>
          <span class="font-weight-bold">Image generations (last 30 days)</span>
        </v-list-item-content>

        <v-list-item-content>
          <v-progress-linear rounded :color="
            getQuotaColour(
              (totalImageGens / project.quota.imageGenerations) * 100
            )
          " height="18" :value="(totalImageGens / project.quota.imageGenerations) * 100">
            <strong>{{ totalImageGens }}/{{
              project.quota.imageGenerations
            }}</strong>
          </v-progress-linear>

        </v-list-item-content>


        <v-list-item-icon></v-list-item-icon>
      </v-list-item>

      <v-list-item class="mb-4">
        <v-list-item-content>
          <span class="font-weight-bold">Text generations (last 30 days)</span>
        </v-list-item-content>

        <v-list-item-content>
          <v-progress-linear rounded :color="
            getQuotaColour(
              (totalImageGens / project.quota.textGenerations) * 100
            )
          " height="18" :value="(textGenStats / project.quota.textGenerations) * 100">
            <strong>{{ totalTextGens }}/{{
              project.quota.textGenerations
            }}</strong>
          </v-progress-linear>

        </v-list-item-content>


        <v-list-item-icon></v-list-item-icon>
      </v-list-item>

      <v-list-item class="mb-4">
        <v-list-item-content>
          <span class="font-weight-bold">Storage</span>
        </v-list-item-content>

        <v-list-item-content>
          <v-progress-linear rounded :color="
            getQuotaColour(
              (totalSize / project.quota.storageGb) * 100
            )
          " height="18" :value="(totalSize / project.quota.storageGb) * 100">
            <strong>{{ imageGenStats.totalSize | size }} out of {{
              project.quota.storageGb
            }} GB</strong>
          </v-progress-linear>

        </v-list-item-content>


        <v-list-item-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item class="mb-4">
        <v-list-item-content>
          <span class="font-weight-bold">Models</span>
        </v-list-item-content>

        <v-list-item-content>
          <v-progress-linear rounded :color="
            getQuotaColour(
              (totalModels / project.quota.models) * 100
            )
          " height="18" :value="(totalModels / project.quota.models) * 100">
            <strong>{{ totalModels }}/{{
              project.quota.models
            }}</strong>
          </v-progress-linear>

        </v-list-item-content>
        <v-list-item-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item class="mb-4">
        <v-list-item-content>
          <span class="font-weight-bold">Inference endpoints</span>
        </v-list-item-content>

        <v-list-item-content>
          <v-progress-linear rounded :color="
            getQuotaColour(
              (totalServers / project.quota.models) * 100
            )
          " height="18" :value="(totalServers / project.quota.models) * 100">
            <strong>{{ totalServers }}/{{
              project.quota.models
            }}</strong>
          </v-progress-linear>

        </v-list-item-content>

        <v-list-item-icon></v-list-item-icon>
      </v-list-item>
    </v-list>

  </v-card-text>
</template>

<script>
export default {
  components: {
  },

  data() {
    return {

    }
  },

  props: {
    project: {
      type: Object,
      required: true
    },
    serverStats: {
      type: Object,
      required: true
    },
    imageGenStats: {
      type: Object,
      required: true
    },
    textGenStats: {
      type: Object,
      required: true
    },
    models: {
      type: Array,
      required: true
    },
  },

  computed: {
    totalImageGens() {
      return this.imageGenStats.pending + this.imageGenStats.processing +
        this.imageGenStats.completed + this.imageGenStats.failed
    },
    totalTextGens() {
      return this.textGenStats.pending + this.textGenStats.processing +
        this.textGenStats.completed + this.textGenStats.failed
    },
    totalSize() {
      // Convert this.imageGenStats.totalSize from bytes to GB
      return Math.round(this.imageGenStats.totalSize / 1000000000)
    },
    totalModels() {
      return this.models.length
    },
    totalServers() {
      let servers = 0
      for (let model of this.models) {
        servers += model.servers.length
      }
      return servers
    },
  },

  methods: {
    getQuotaColour(usage) {
      if (usage < 70) {
        return 'green accent-3'
      }
      if (usage < 80) {
        return 'amber lighten-1'
      }

      // return 'pink lighten-1'
      return 'red darken-3'
    },
  }
}
</script>
