var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"mb-4"},[_c('v-list-item-content',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Image generations (last 30 days)")])]),_c('v-list-item-content',[_c('v-progress-linear',{attrs:{"rounded":"","color":_vm.getQuotaColour(
            (_vm.totalImageGens / _vm.project.quota.imageGenerations) * 100
          ),"height":"18","value":(_vm.totalImageGens / _vm.project.quota.imageGenerations) * 100}},[_c('strong',[_vm._v(_vm._s(_vm.totalImageGens)+"/"+_vm._s(_vm.project.quota.imageGenerations))])])],1),_c('v-list-item-icon')],1),_c('v-list-item',{staticClass:"mb-4"},[_c('v-list-item-content',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Text generations (last 30 days)")])]),_c('v-list-item-content',[_c('v-progress-linear',{attrs:{"rounded":"","color":_vm.getQuotaColour(
            (_vm.totalImageGens / _vm.project.quota.textGenerations) * 100
          ),"height":"18","value":(_vm.textGenStats / _vm.project.quota.textGenerations) * 100}},[_c('strong',[_vm._v(_vm._s(_vm.totalTextGens)+"/"+_vm._s(_vm.project.quota.textGenerations))])])],1),_c('v-list-item-icon')],1),_c('v-list-item',{staticClass:"mb-4"},[_c('v-list-item-content',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Storage")])]),_c('v-list-item-content',[_c('v-progress-linear',{attrs:{"rounded":"","color":_vm.getQuotaColour(
            (_vm.totalSize / _vm.project.quota.storageGb) * 100
          ),"height":"18","value":(_vm.totalSize / _vm.project.quota.storageGb) * 100}},[_c('strong',[_vm._v(_vm._s(_vm._f("size")(_vm.imageGenStats.totalSize))+" out of "+_vm._s(_vm.project.quota.storageGb)+" GB")])])],1),_c('v-list-item-icon')],1),_c('v-list-item',{staticClass:"mb-4"},[_c('v-list-item-content',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Models")])]),_c('v-list-item-content',[_c('v-progress-linear',{attrs:{"rounded":"","color":_vm.getQuotaColour(
            (_vm.totalModels / _vm.project.quota.models) * 100
          ),"height":"18","value":(_vm.totalModels / _vm.project.quota.models) * 100}},[_c('strong',[_vm._v(_vm._s(_vm.totalModels)+"/"+_vm._s(_vm.project.quota.models))])])],1),_c('v-list-item-icon')],1),_c('v-list-item',{staticClass:"mb-4"},[_c('v-list-item-content',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Inference endpoints")])]),_c('v-list-item-content',[_c('v-progress-linear',{attrs:{"rounded":"","color":_vm.getQuotaColour(
            (_vm.totalServers / _vm.project.quota.models) * 100
          ),"height":"18","value":(_vm.totalServers / _vm.project.quota.models) * 100}},[_c('strong',[_vm._v(_vm._s(_vm.totalServers)+"/"+_vm._s(_vm.project.quota.models))])])],1),_c('v-list-item-icon')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }